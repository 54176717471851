import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    //attributes: {title:'Dashboard tooltip'}
  },
  {
    name: 'Resources',
    url: '/resources',
    icon: 'fa fa-plane',
  },
  {
    name: 'Aircraft Class',
    url: '/aircraft-class',
    icon: 'fa fa-paper-plane',
  },
  {
    name: 'Resource Type',
    url: '/resource-type',
    icon: 'fa fa-list-ul',
  },
  {
    name: 'Maintenance',
    url: '/maintenance',
    icon: 'icon-settings',
  },
 {
    name: 'Finance',
    url: '/finance',
    icon: 'fa fa-usd'
  },
  {
    name: 'Schedule',
    url: '/schedule',
    icon: 'icon-settings',
  },
  {
    name: 'Squawks',
    url: '/squawks',
    icon: 'fa fa-wrench',
  },
  {
    name: 'Course',
    url: '/course',
    icon: 'icon-list',
  },
   
];
