import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from './service/app-http/common.service';
import { Chart } from 'chart.js';
import { SingleDataSet, Label, Color } from 'ng2-charts';

@Component({
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private router: Router,
    private commonService: CommonService) {
    // this.commonService.show('hwllo how are you', 'success')
    // this.commonService.show('hwllo how are you', 'error')
    // this.commonService.show('hwllo how are you', 'warning')
    // this.commonService.show('hwllo how are you', 'info')
  }

  private items: Array<any> = [];



  ngOnInit() {
 
    this.items.push("text")
    this.router.events.subscribe((evt) => {
      
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

  }
}
