
import { throwError as observableThrowError, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AppHttpService {
    public hostUrl = environment.API_URL;

    constructor(
        public localStorageService: LocalStorageService, public httpClient: HttpClient,
        public router: Router) { }
    getHeader(headerOptions, qparams = {}, doNotSendAuthorizationParam) {
        let headerParams = {};
        if (doNotSendAuthorizationParam !== true && this.localStorageService.getSessionId()) {
            //send authorization param
            headerParams['x-auth-token'] = this.localStorageService.getSessionId();
        }
        if (headerOptions) {
            headerParams = { ...headerParams, ...headerOptions }
        }
        let params: HttpParams = new HttpParams();
        for (let key in qparams) {
            params.set(key, qparams[key]);
        }
        let headers = new HttpHeaders(headerParams);
        return headers;
    }

    get(url, params: any = {}, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false) {
        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        return this.httpClient.get<any>(this.hostUrl + url, { params: params, headers: httpOptions }).pipe(map(data => {
            if (data) {
                return data
            } else {
                return []
            }
        }), tap(),
            catchError(this.handleError)
        )
    }

    post(url, params: any = {}, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false) {
        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        console.log(this.hostUrl + url, params, { headers: httpOptions });
        
        return this.httpClient.post<any>(this.hostUrl + url, params, { headers: httpOptions }).pipe(map(data => {
            if (data) {
                return data
            } else {
                return []
            }
        }), tap(), catchError(this.handleError))
    }

    put(url, params: any = {}, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false) {
        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        return this.httpClient.put<any>(this.hostUrl + url, params, { headers: httpOptions }).pipe(map(data => {
            if (data) {
                return data
            } else {
                return []
            }
        }), tap(), catchError(this.handleError))
    }

    delete(url, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false) {
        let httpOptions = this.getHeader(headerOptions, null, doNotSendAuthorizationParam);
        return this.httpClient.delete<any>(this.hostUrl + url, { headers: httpOptions })
            .pipe(map(data => {
                if (data) {
                    return data
                } else {
                    return []
                }
            }), tap(), catchError(this.handleError))
    }
    post_csv(url, file){
        const uploadedFile = new FormData();
        uploadedFile.append( 'file', new Blob([file], { type: 'text/csv' }), file.name);
        return this.httpClient.post<any>(this.hostUrl + url, uploadedFile).pipe(map(data => {
            if (data) {
                return data
            } else {
                return []
            }
        }), tap(), catchError(this.handleError))
    }

    post_file(url, file, fileData, params: any = {}, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false){
        const uploadedFile = new FormData();
        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        for(let i = 0; i < file.length; i++){
            console.log(file[i]);
            uploadedFile.append('files[]', file[i]);
        }
       
        uploadedFile.append('data', JSON.stringify(fileData));
        
       
        return this.httpClient.post<any>(this.hostUrl + url, uploadedFile, { headers: httpOptions }).pipe(map(data => {
            if (data) {
                return data
            } else {
                return []
            }
        }), tap(), catchError(this.handleError));
    }

    get_csv(url){
        return this.httpClient.get(this.hostUrl + url, { responseType: 'blob' }).pipe(map(data  => {
            if (data) {
                return new Blob([data], { type : 'application/csv' });
            } else {
                return []
            }
        }), tap(), catchError(this.handleError));
    }

    get_file(url, path, params: any = {}, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false){
        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        return this.httpClient.post(this.hostUrl + url, path, {headers: httpOptions, responseType: 'blob'}).pipe(map(data  => {
            if (data) {
                return new Blob([data], { type : 'application/jpeg' });
            } else {
                return []
            }
        }), tap(), catchError(this.handleError));
    }
    
    get_Weather(url, location, params: any = {}, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false){
        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        const formData = new FormData();
        formData.append('location', location);
        return this.httpClient.post<any>(this.hostUrl + url, formData, { params: params, headers: httpOptions }).pipe(map(data => {
            if (data) {
                return data
            } else {
                return []
            }
        }), tap(),
            catchError(this.handleError)
        );
    }

    public handleError(error: HttpErrorResponse) {
        // return an observable with a user-facing error message
        return throwError(error.error);
    };
}
