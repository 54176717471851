import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthorizedSideNavService } from '../../../service/authorized-side-nav-service.service';
import { LocalStorageService } from '../../../service/local-storage/local-storage.service';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-authorized-side-nav',
  templateUrl: './authorized-side-nav.component.html',
  styleUrls: ['./authorized-side-nav.component.css']
})
export class AuthorizedSideNavComponent implements OnInit {

  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  Clicked : boolean
  private userDetails: any = {};
  public isSuperAdmin: Boolean = false;
  step: String = 'step1';
  smallNav = false;
  @Output() sendItem = new EventEmitter<boolean>();

  addNewItem(value: boolean) {
    this.sendItem.emit(value);
  }

  constructor(
    public sideNavService: AuthorizedSideNavService,
    private localStorageService: LocalStorageService,
    private breakpointObserver: BreakpointObserver
  ) { }
 
  ngOnInit() { 
    this.getUserInfo();
  }

  collapse(){
    this.smallNav = !this.smallNav;
    this.addNewItem(this.smallNav);
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  onClick(check){
    // console.log(check);
    if(check==1){
      this.tab = 'tab1';
    }else if(check==2){
      this.tab = 'tab2';
    }else{
      this.tab = 'tab3';
    }     
  }

  onSwitch(check){
    switch (check) {
      case 1:
        return 'tab1';
      case 2:
        return 'tab2';
      case 3:
        return 'tab3';
    }
  }
  
  getUserInfo(){
    this.userDetails = this.localStorageService.getDetails();
    if(this.userDetails.role === "super-admin"){
      this.isSuperAdmin = true;
    }
  }
  
}
