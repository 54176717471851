import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// import social login module
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';

// import device detector module
import { DeviceDetectorService } from 'ngx-device-detector';

//import {MatTooltipModule} from '@angular/material';
//import 'hammerjs';  

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  // AppAsideModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatepickerModule, DatePickerComponent } from 'ngx-bootstrap/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormField } from '@angular/material/form-field'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

// Module - NgxCheckboxModule
import { NgxCheckboxModule } from 'ngx-checkbox';
// Chart.js import
import { ChartsModule } from 'ng2-charts';
import { DisableControlDirective } from './directives/disable-control.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Sidebar Nav
import { AuthorizedSideNavComponent } from './containers/default-layout/authorized-side-nav/authorized-side-nav.component';

import { LOCALE_ID } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

// import { SettingsComponent } from './views/settings/settings.component';
// import { NewResourceDetailComponent } from './views/resources/resources/new-resource-detail/new-resource-detail.component';


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    //MatTooltipModule,
    AppRoutingModule,
    // AppAsideModule,
    AppFooterModule,
    AppHeaderModule,
    FormsModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      progressBar: true,
    }),
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DatepickerModule,
    ChartsModule,
    NgbModule,
    MatProgressSpinnerModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
		SocialLoginModule,

    // MatDatepickerModule,
    // MatFormField,
    //----- NewResourceDetailComponent
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    DropdownDirective,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    AuthorizedSideNavComponent,
    DisableControlDirective,
 
   
    // SettingsComponent,
    //------- NewResourceDetailComponent,
  ],
  exports: [
    
    ChartsModule,

  ],
  providers: [
     {           /*I need delete the # hash fro get the token from the callback*/ 
       provide: LocationStrategy, 
       useClass: HashLocationStrategy
     },
		{
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId')
          },
        ]
      } as SocialAuthServiceConfig,
    },
		{
			provide: DeviceDetectorService,
		},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
