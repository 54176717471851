import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { navItems } from '../../_nav';
import { LocalStorageService } from '../../service/local-storage/local-storage.service';
import '../../../../node_modules/jquery/dist/jquery.min.js';


import { AuthorizedSideNavService } from '../../service/authorized-side-nav-service.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = true;
  public navItems = navItems;
  public dropdown = false;
  public dropdownSchedules = false;
  public title = "";
  public dropdownLinks = [];

  public dropdownLinksDashboard = [
    {'title': 'Admin', 'routePathArray': ['/', 'dashboard', 'admin'], 'icon': 'icon-speedometer'}, 
    {'title': 'Finance', 'routePathArray': ['/', 'dashboard', 'finance'], 'icon': 'icon-speedometer'}, 
    {'title': 'Dispatch', 'routePathArray': ['/', 'dashboard', 'dispatch'], 'icon': 'icon-speedometer'},
  ];

  public dropdownLinksResources = [
    {'title': 'Resources', 'routePathArray': ['/', 'resources'], 'icon': 'fas fa-plane'}, 
    {'title': 'Aircraft Classes', 'routePathArray': ['/', 'aircraft-class'], 'icon': 'fas fa-paper-plane'}, 
    {'title': 'Resource Types', 'routePathArray': ['/', 'resource-type'], 'icon': 'fas fa-paper-plane'},
    {'title': 'Pilot Logs', 'routePathArray': ['/', 'pilot-log'], 'icon': 'fas fa-paper-plane'}
  ];

  public dropdownLinksMaintenance = [
    {'title': 'Scheduled Maintenance', 'routePathArray': ['/', 'maintenance', 'schedule'], 'icon': 'fas fa-calendar'}, 
    {'title': 'Maintenance Activty', 'routePathArray': ['/', 'maintenance', 'activity'], 'icon': 'fas fa-calendar-times'}, 
    {'title': 'Parts Inventory', 'routePathArray': ['/', 'maintenance', 'parts-inventory'], 'icon': 'fas fa-calendar'},
    {'title': 'Airworthiness Directive', 'routePathArray': ['/', 'maintenance', 'airworthiness-directive'], 'icon': 'fas fa-calendar-times'}, 
    {'title': 'Maintenance Archives', 'routePathArray': ['/', 'maintenance', 'archives'], 'icon': 'fas fa-calendar-times'}, 
  ];

  public dropdownLinksSquawks = [
    {'title': 'Squawks', 'routePathArray': ['/', 'squawks'], 'icon': 'icon-settings'}, 
    {'title': 'Archived Squawks', 'routePathArray': ['/', 'squawks', 'archived-squawks'], 'icon': 'icon-settings'}
  ];

  public dropdownLinksSchedules = [
    {'title': 'Schedules', 'routePathArray': ['/', 'schedule'], 'icon': 'fas fa-calendar'}, 
    {'title': 'Schedule Archives', 'routePathArray': ['/', 'schedule', 'schedule-archive'], 'icon': 'fas fa-calendar-times'}, 
    {'title': 'Schedule Requests', 'routePathArray': ['/', 'schedule', 'schedule-requests'], 'icon': 'fas fa-calendar'}, 
    {'title': 'Courses', 'routePathArray': ['/', 'course'], 'icon': 'fas fa-book-open'}];

  public dropdownLinksFinance = [
    {'title': 'Fuel Expenses', 'routePathArray': ['/', 'finance', 'fuel-expense'], 'icon': 'fas fa-gas-pump'}, 
    {'title': 'Maintenance Expenses', 'routePathArray': ['/', 'finance', 'maintenance-expense'], 'icon': 'fas fa-user-cog'}, 
    {'title': 'Instructor Expenses', 'routePathArray': ['/', 'finance', 'instructor-expense'], 'icon': 'fas fa-user'}, 
    {'title': 'Trainee Income', 'routePathArray': ['/', 'finance', 'trainee-income'], 'icon': 'fas fa-graduation-cap'},
    {'title': 'Settings', 'routePathArray': ['/', 'finance', 'settings'], 'icon': 'fas fa-graduation-cap'}];

  public dropdownLinksUserManagement = [
      {'title': 'Trainees', 'routePathArray': ['/', 'user-management', 'trainees'], 'icon': 'icon-settings'}, 
      {'title': 'Instructors', 'routePathArray': ['/', 'user-management', 'instructors'], 'icon': 'icon-settings'}, 
      {'title': 'Instructor Roster', 'routePathArray': ['/', 'user-management', 'instructors-roster'], 'icon': 'fas fa-calendar'},
    ];

  

  public rawUrl = "";
  public url = "";
  public small: boolean;
  public copyrightYear: number = this.getCopyrightYear();
  public isSuperAdmin: Boolean = false;
  private userDetails: any = {};

  getNav(item: boolean){
    console.log('getNav item', item)
    this.small = item;
  }
  /*begin script*/
  ngOnInit() {    
    this.setTitle();   
    this.getUserInfo();

    //transition for profile dropdown icon
    document.addEventListener('click', (e) => {
      var container = document.getElementById('dropdown-icon-container');
      var icon = document.getElementById('dropdown-icon');
      var active = 'active-profile-dropdown';
      var inactive = 'profile-dropdown-icon';
      let target = e.target;

      if (target == container || target == icon) {
        if (icon.classList.contains(active)) {
          icon.classList.remove(active);
          icon.classList.add(inactive);
        } else if (!icon.classList.contains(active)) {
          icon.classList.remove(inactive);
          icon.classList.add(active);
        }
      } else if (target != container) {
        icon.classList.remove(active);
        icon.classList.add(inactive);
      }
    });

    //clicking on the media menu button (when the window is smaller)
    // document.addEventListener('click', e => {
    //   var menu = document.getElementsByClassName('navbar-toggler')[0];
    //   var menuIcon = document.getElementsByClassName('navbar-toggler-icon')[0];
    //   var hover = document.getElementById('menu-hover');
    //   var target = e.target;
    //   if (target == menu || target == menuIcon) {
    //     if (!hover.classList.contains('hover-active')) {
    //       hover.style.display = "inline";
    //       hover.classList.add('hover-active');
    //     } else if (hover.classList.contains('hover-active')) {
    //       hover.style.display = "none";
    //       hover.classList.remove('hover-active');
    //     }
    //   }
    // })

    //hovering over the media menu button (when the window is smaller)
    // document.addEventListener('mouseover', e => {
    //   var menu = document.getElementsByClassName('navbar-toggler')[0];
    //   var menuIcon = document.getElementsByClassName('navbar-toggler-icon')[0];
    //   var hover = document.getElementById('menu-hover');
    //   var target = e.target;
    //   if (target == menu || target == menuIcon) {
    //     hover.style.display = "inline";
    //   } else if (target != menu || target != menuIcon) {
    //     if (hover.classList.contains('hover-active')) {
    //       hover.style.display = "inline";  
    //     } else {
    //       hover.style.display = "none";
    //     }
    //   }
    // })
    
  }

  isContainingAll(arrayToCheck, arrayValues){
    for (var i=0; i<arrayValues.length; i++){
      if(!arrayToCheck.includes(arrayValues[i])){
        return false;
      }
    }
    return true;
  }

  /*end script*/
  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private router: Router,
    public sideNavService: AuthorizedSideNavService) {
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
            // Show loading indicator
        }
        if (event instanceof NavigationEnd) {
            this.rawUrl = event.url;
            this.setTitle();
        }
        if (event instanceof NavigationError) {
            // Hide loading indicator
            // Present error to user
            console.log(event.error);
        }
      });
    }
  


  setTitle(){
    var urlStrings = this.rawUrl.split('/');
    var url = urlStrings[urlStrings.length -1].replace('-',' ');

    if(this.isContainingAll(urlStrings, ['dashboard', 'admin'])){
      this.dropdown = true;
      this.dropdownLinks = this.dropdownLinksDashboard;
      this.title = 'Admin Dashboard';

    } else if(this.isContainingAll(urlStrings, ['dashboard', 'finance'])){
        this.dropdown = true;
        this.dropdownLinks = this.dropdownLinksDashboard;
        this.title = 'Finance Dashboard';

    } else if(this.isContainingAll(urlStrings, ['dashboard', 'dispatch'])){
          this.dropdown = true;
          this.dropdownLinks = this.dropdownLinksDashboard;
          this.title = 'Dispatch Dashboard';

    } else if (url=='resources' || url=='aircraft class' || url=='resource type' || url=='pilot log') {
      this.dropdown = true;
      this.dropdownLinks = this.dropdownLinksResources;
      
      if (url == 'resources') {this.title = 'Resources';}
      else if (url == 'aircraft class') {this.title = 'Aircraft Classes';}
      else if (url == 'resource type') {this.title = 'Resource Types';}
      else if (url = 'pilot log') {this.title = 'Pilot Logs';}
      else {this.title = url};

    } else if (urlStrings.includes('maintenance')){
      this.dropdown = true;
      this.dropdownLinks = this.dropdownLinksMaintenance;

      if (url == 'schedule') {this.title = 'Scheduled Maintenance';}
      else if (url == 'activity') {this.title = 'Maintenance Activity';}
      else if (url == 'parts inventory') {this.title = 'Parts Inventory';}
      else if (url == 'airworthiness directive') {this.title = 'Airworthiness Directive';}
      else if (url = 'archives') {this.title = 'Maintenance Archives';}
      else if (urlStrings.includes('activities list')) {this.title = 'Activity';}
      else {this.title = url};

    } else if (urlStrings.includes('squawks')){
      this.dropdown = true;
      this.dropdownLinks = this.dropdownLinksSquawks;

      if (urlStrings.includes('archived squawks')) {this.title = 'Archived Squawks';}
      else {this.title = url;}

    } else if (urlStrings.includes('schedule')){
      this.dropdown = true;
      this.dropdownLinks = this.dropdownLinksSchedules;

      if (url == 'schedule view') {this.title = 'Schedules - Calendar View';}
      else if (url == 'schedule') {this.title = 'Schedules - List View';}
      else if (url == 'schedule requests') {this.title = 'Schedule Requests';}
      else if (url == 'schedule detail') {this.title = 'New Schedule';}

      // TODO: on Schedule Detail, pull in trainee last name for title
      // this.apiService.getSchedule(url).subscribe(res => {
      //   var scheduledTraineeId = res.data.schedule.trainees[0];
      //   console.log(scheduledTraineeId);
      //   this.apiService.getTraineeById(scheduledTraineeId).subscribe(res => {
      //     console.log(res);
      //   })
      // })

      else if (url = 'schedule archive') {this.title = 'Schedule Archives';}
      else {this.title = url};
    
    } else if (urlStrings.includes('course')){
      if (url='course') {
        this.dropdown = true;
        this.dropdownLinks = this.dropdownLinksSchedules;
        this.title = 'Courses'
      }
      else {this.title = url}

    } else if (urlStrings.includes('finance')){
      this.dropdown = true;
      this.dropdownLinks = this.dropdownLinksFinance;

      if (urlStrings.includes('instructor-expense')) {this.title = 'Instructor Expenses';}
      else if (urlStrings.includes('fuel-expense')) {this.title = 'Fuel Expenses';}
      else if (urlStrings.includes('trainee-income')) {this.title = 'Trainee Income';}
      else if (urlStrings.includes('maintenance-expense')) {this.title = 'Maintenance Expenses';}
      else if (urlStrings.includes('fuel-expense-by-vendor')){
        this.apiService.getFuelVendorById(url).subscribe(res => {
          this.title = res.data.companyName;
        })} 
      else if(
        urlStrings.includes('instructor-expenses-by-instructor') ||
        urlStrings.includes('trainee-income-by-trainee') ){
        this.apiService.getUserNameById(url).subscribe(res => {
          this.title =  res.data.user.name;
        })}
     
      else if(urlStrings.includes('maintenance-expense-by-aircraft')){
        this.apiService.getResource(url).subscribe(res => {
          this.title =  res.data.tailNumber;
        })}
      else {this.title = url};

    } else if (urlStrings.includes('user-management')){
      this.dropdown = true;
      this.dropdownLinks = this.dropdownLinksUserManagement;

      if (url == 'trainees') {this.title = 'Trainees';}
      else if (this.isContainingAll(urlStrings, ['instructors-roster', 'view']))
        {this.title = 'Instructor Roster';}
      else {this.title = url};

    } else {
      this.dropdown = false;
      this.title = url;
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  onLogout() {
    this.localStorageService.clear();
  }

  public getCopyrightYear(): number {
    return new Date(Date.now()).getFullYear();
  }

  getUserInfo(){
    this.userDetails = this.localStorageService.getDetails();
    if(this.userDetails.role === "super-admin"){
      this.isSuperAdmin = true;
    }
  }

}
