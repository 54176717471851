import { 
    Directive, 
    HostBinding, 
    Input, 
    OnInit,
    HostListener
} from '@angular/core';

@Directive({
    selector: '[appDropdown]'
})

export class DropdownDirective implements OnInit {
    @Input() defaultHeight: string = '20px';
    @Input() activeHeight: string = '100px';

    @HostListener('click') boojie() {
        this.height = this.isOpen ? this.defaultHeight : this.activeHeight;
        this.isOpen = !this.isOpen;
    }

    @HostBinding('style.height') height: string;
    private isOpen: boolean = false;

    ngOnInit() {
        this.height = this.defaultHeight;
    }
}