import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from '../../service/app-http/common.service';
import { LocalStorageService } from '../../service/local-storage/local-storage.service';
import { ApiService } from '../../service/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  public loginForm: FormGroup;
  public isSubmitted: Boolean = false;
  public loginFormMessage: any = {
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' }
    ],
    password: [
      { type: 'required', message: 'Password is required' }
    ]
  }
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService,
    private localStorageService: LocalStorageService
  ) {

  }
  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  // On submit button click
  onLogin() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return false;
    }
    let requestObj: Object = {
      emailOrPhone: this.loginForm.value.email,
      password: this.loginForm.value.password
    };
    this.apiService.login(requestObj)
      .subscribe((res: any) => {
        this.localStorageService.setDetails(res.data);
        this.commonService.show(res.msg, 'success');

        // check if automation settings are already saved
        this.apiService.getSettings().subscribe(res => {
          if(res.data.length <= 0){
            this.commonService.show('Please fill out automation settings before continuing.', 'info');
            this.router.navigate(['/settings']);
          }else{
            this.router.navigate(['/dashboard']);
          }
        })
        // this.router.navigate(['/dashboard']);
      }, error => {
        this.commonService.show(error.errors.msg, 'error');
      });
    localStorage.setItem('currentUser', JSON.stringify({ email: this.loginForm.value.email})); 
  }

}
