import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from '../../service/app-http/common.service';
import { ApiService } from '../../service/api.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})

export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;
	private signupType: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService,
		private authService: SocialAuthService,
	private deviceService: DeviceDetectorService
  ) {
		this.signupType = 'normal';
  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password: ['', Validators.required],
			firstName: [],
			middleName: [],
			lastName: [],
			governmentId: [],
			role: [],
			phoneNumber: [],
    });
  }

  onRegister() {
    let requestObj: Object = {
      firstName: this.registerForm.value.firstName,
			middleName: this.registerForm.value.middleName,
			lastName: this.registerForm.value.lastName,
			role: this.registerForm.value.role,
			governmentId: this.registerForm.value.governmentId,
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
			phoneNumber: this.registerForm.value.phoneNumber,
			deviceId: this.deviceService.getDeviceInfo().os,
			signupType: this.signupType,
    };

    this.apiService.register(requestObj)
      .subscribe((res: any) => {
        this.commonService.show(res.msg, 'success');
        this.router.navigate(['/']);
      }, error => {
        this.commonService.show(error.errors.msg, 'error');
      });
  }

	// Facebook registration will only work once we get a provier ID
	// Facebook registration will only work with https
	onFacebook(){
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => {
				this.signupType = 'facebook';	
        console.log("sign in data: " , userData);
      }
    );
	}

	onTwitter() {
	
	}
}
