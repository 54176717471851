import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AdminAuthGuard } from './guard/admin-auth.guard';
// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule)
      },
      {
        path: 'resources',
        loadChildren: () => import('./views/resources/resources/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./views/maintenance/maintenance.module').then(m => m.MaintenanceModule)
      },
      
      {
        path: 'aircraft-class',
        loadChildren: () => import('./views/aircraft-class/aircraft-class/aircraft-class.module').then(m => m.AircraftClassModule)
      },
      {
        path: 'resource-type',
        loadChildren: () => import('./views/resource-type/resource-type.module').then(m => m.ResourceTypeModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./views/finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: 'schedule',
        loadChildren: () => import('./views/schedule/schedule-main/schedule-main.module').then(m => m.ScheduleMainModule)
      },
	    {
        path: 'squawks',
        loadChildren: () => import('./views/squawks/squawks/squawks.module').then(m => m.SquawksModule)
      },
      {
        path: 'course',
        loadChildren: () => import('./views/course/course.module').then(m => m.CourseModule)
      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [AdminAuthGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'pilot-log',
        loadChildren: () => import('./views/pilot-log/pilot-log.module').then(m => m.PilotLogModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AdminAuthGuard],
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
