import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppHttpService } from './app-http/app-http.service';
@Injectable({
	providedIn: 'root'
})
export class ApiService {
	
	constructor(private httpService: AppHttpService) {
	}
	// login API
	login(body) {
		return this.httpService.post('user/login', body);
	}
	// register API
	register(body) {
		return this.httpService.post('user/register', body);
	}
	createAircraftClass(body) {
		return this.httpService.post('aircraft-class/create-aircraft-class', body);
	}
	getAllAircraftClasses() {
		return this.httpService.get('aircraft-class/get-aircraft-classes');
	}
	getAircraftClass(id) {
		return this.httpService.get('aircraft-class/get-aircraft-class/' + id);
	}
	updateAircraftClass(id, body) {
		return this.httpService.put('aircraft-class/edit-aircraft-class/' + id, body);
	}
	deleteAircraftClass(id) {
		return this.httpService.delete('aircraft-class/delete-aircraft-class/' + id);
	}
	getAircraftClassId(aircraft) {
		return this.httpService.get('aircraft-class/get-aircraft-class-id/' + aircraft);
	}

	/**
	 * RESOURCE ROUTES
	 */
	// create resource API
	createResource(body) {
		return this.httpService.post('resource/create-resource', body);
	}
	// get resources API
	getAllResources() {
		return this.httpService.get('resource/get-resources');
	}
	getAllInserviceResources() {
		return this.httpService.post('resource/get-in-service-resources');
	}
	//get resource by Id API
	getResource(id){
		return this.httpService.get('resource/' + id);
	}
	//get resource by resourceRegistrationNumber
	getResourceByResourceRegistrationNumber(resourceRegistrationNumber){
		return this.httpService.get('resource/' + resourceRegistrationNumber);
	}
	// edit resource API
	editResource(resourceId, body) {
		return this.httpService.put('resource/' + resourceId, body);
	}
	// change resources status API
	changeResourcesStatus(body) {
		return this.httpService.post('resource/change-status', body);
	}
	// delete resources API
	deleteResources(body) {
		return this.httpService.post('resource/delete-resources', body);
	}
	
	/**
	 * RESOURCE TYPE ROUTES
	 */
	// create resource type API
	createResourceType(body) {
		return this.httpService.post('resource-type/create-resource-type', body);
	}
	getResourceTypes(body) {
		return this.httpService.post('resource-type/get-resource-types', body);
	}
	getAllResourceTypes() {
		return this.httpService.post('resource-type/get-resource-types');
	}
	getResourceType(id) {
		return this.httpService.get('resource-type/' + id);
	}
/*
	getResourceTypeId(resourceType) {
		return this.httpService.get('resource-type/get-resource-type-id/' + resourceType);
	}
*/
	updateResourceType(id, body) {
		return this.httpService.put('resource-type/' + id, body);
	}
	changeResourceTypesStatus(body) {
		return this.httpService.post('resource-type/change-status', body);
	}
	deleteResourceTypes(body) {
		return this.httpService.post('resource-type/delete-resource-types', body);
	}

	/**
	 * SUPER ADMIN ROUTES
	 */
	adminGetAllInstructors(){
		return this.httpService.post('admin-user/get-all-instructors');
	}
	adminGetAllTrainees(){
		return this.httpService.post('admin-user/get-all-trainees');
	}
	createTrainee(body){
		return this.httpService.post('admin-user/add-trainee', body);
	}
	createInstructor(body){
		return this.httpService.post('admin-user/add-instructor', body);
	}
	getTraineeById(id){
		return this.httpService.get('admin-user/get-trainee/' + id);
	}
	getInstructorById(id){
		return this.httpService.get('admin-user/get-instructor/' + id);
	}
	getTraineeFiles(id){
		return this.httpService.get('admin-user/download-files/' + id);
	}
	updateTraineeDetails(id, body){
		return this.httpService.post('admin-user/change-trainee-details/' + id, body);
	}
	updateInstructorDetails(id, body){
		return this.httpService.post('admin-user/change-instructor-details/' + id, body);
	}
	deleteUser(id){
		return this.httpService.delete('admin-user/delete-user/' + id);
	}
	postFile(id,file, fileData){
		return this.httpService.post_file('admin-user/upload-files/' + id, file, fileData);
	}
	getFile(id, path){
		return this.httpService.get_file('admin-user/get-files/' + id + '/download', path);
	}
	deleteFile(fileKey) {
		return this.httpService.post('admin-user/delete-s3file', fileKey)
	}

	/** 
	 * SQUAWK ROUTES  
	 */
	// add a new squawk
	createSquawk(body) {
		return this.httpService.post('squawk/create-squawk', body);
	}
	// get all the active squawk 
	getActiveSquawks() { 
		return this.httpService.post('squawk/get-active-squawks');
	}
	// get all the archived (inactive) squawk
	getArchivedSquawks() { 
		return this.httpService.post('squawk/get-archived-squawks');
	}
	// get all the active squawks related to a specific aircraft
	getActiveSquawksByAircraft(resourceId) {
		return this.httpService.get('squawk/get-active-squawks-by-aircraft/' + resourceId);
	}
	// get all the archived (inactive) squawks related to a specific aircraft
	getArchivedSquawksByAircraft(resourceId) {
		return this.httpService.get('squawk/get-archived-squawks-by-aircraft/' + resourceId);
	}
	// edit an exisiting squawk given the ObjectId
	editSquawk(id, body) {
		return this.httpService.put('squawk/edit-squawk/' + id, body);
	}
	// delete an exisiting squawk given the ObjectId
	deleteSquawk(id) {
		return this.httpService.post('squawk/delete-squawk/' + id);
	}
	// upload attachment by squawkId
	uploadAttachment(id,file,fileData) {
		// console.log(body)
		return this.httpService.post_file("squawk/upload/" + id, file,fileData )
	}
	// delete attachment file by squawkId and update s3 bucket
	deleteSquawkAttachment(body) {
		return this.httpService.post("squawk/delete-file/", body);
	}

	/** FLIGHT LOGBOOK API SERVICE */
	getFlightLogBooksFromDates(startDate: Date, endDate: Date) {
		let params: HttpParams = new HttpParams();
		params = params.append('startDate', startDate.toISOString());
		params = params.append('endDate', endDate.toISOString());
		
		return this.httpService.get('flight-log-book/get-all-flight-log-books-from-dates', params);
	}
	getAllFlightLogBooks() {
		return this.httpService.get('flight-log-book/get-all-flight-log-books');
	}
	updateFlightLogBook(id, body){
		return this.httpService.put('flight-log-book/update-log-book-by-id/' + id, body)
	}
	createFlightLogBook(body){
		return this.httpService.post('flight-log-book/create-log-book', body);
	}
	
	/** FUEL EXPENSE API SERVICE */
	createFuelExpense( body = {}) {
		return this.httpService.post('finance/fuel/create-fuel-expense', body);
	}
	getFuelExpenses(body = {}) {
		return this.httpService.post('finance/fuel/get-all-fuel-expenses', body)
	}
	getFuelExpense( id, body = {}) {
		return this.httpService.get('finance/fuel/get-fuel-expense/' + id, body);
	}

	getFuelExpensesByDate(startDate: Date, endDate: Date = null) {
		let params: HttpParams = new HttpParams();
		params = params.append('startDate', startDate.toISOString());
		params = params.append('endDate', endDate ? endDate.toISOString() : '');
		return this.httpService.get('finance/fuel/get-fuel-expenses-within-time-range', params);
	}

	getFuelExpenseByVendor(vendorId){
		return this.httpService.post('finance/fuel/get-fuel-expenses-by-vendor/'+ vendorId);
	}
	updateFuelExpense( id, body = {}) {
		return this.httpService.put('finance/fuel/update-fuel-expense/' + id, body);
	}
	deleteFuelExpenses(ids) {
		return this.httpService.post('finance/fuel/delete-fuel-expenses', { selectedIds: ids });
	}
	
	/**
	 * INSTRUCTORS EXPENSE API SERVICE
	 */
	createInstructorExpense(body) {
		return this.httpService.post('finance/instructor/create-expense', body);
	}
	getAllInstructors() {
		return this.httpService.post('user/get-all-instructors');
	}
	getAllInstructorExpense(){
		return this.httpService.post('finance/instructor/get-all-expense');
	}
	getInstructorExpense(id){
		return this.httpService.post('finance/instructor/get-expense/' + id);
	}

	getInstructorExpensesWithinTimeRange(startDate: Date, endDate: Date) {
		let params: HttpParams = new HttpParams();
		params = params.append('startDate', startDate.toISOString());
		params = params.append('endDate', endDate.toISOString());
		return this.httpService.get('finance/instructor/get-expenses-within-time-range', params);
	}

	getInstructorExpenseByInstructor(id){
		return this.httpService.post('finance/instructor/get-expenses-by-instructor/' + id);
	}
	updateInstructorExpense(id, body) {
		return this.httpService.put('finance/instructor/update-expense/' + id, body);
	}
	// delete multiple instructor expenses
	deleteInstructorExpenses(ids) {
		return this.httpService.post('finance/instructor/delete-instructor-expenses', { selectedIds: ids });
	}

	/** INCOMES */
	getIncomeById(incomeId: string) {
		return this.httpService.get('finance/income/' + incomeId);
	}

	getAllIncomes() {
		// TODO: implement this method
		return null;
	}

	createIncome(type: 'lesson' | 'maintenance',
				 amountInSmallestCurrencyUnit: number,
				 customerId: string,
				 memo: string,
				 dueDate: Date,
				 receiverId: string = undefined,
				 incomeId: string = undefined,
				 currency: string = 'usd',
				 stripeInvoiceId: string = undefined) {
		const body: object = {
			type,
			amountExpected: amountInSmallestCurrencyUnit,
			customerId,
			memo,
			dueDate: dueDate.toString(),
			receiverId,
			incomeId,
			currency
		}
		return this.httpService.post('finance/income', body);
	}

	connectIncomeToStripeInvoiceMiddleware(incomeId: string, 
										   invoiceId: string) {
		return this.httpService.post('finance/income/connect-to-stripe-invoice', { incomeId, invoiceId });
	}

	/** TRAINEES */
	getAllTrainees() {
		return this.httpService.post('user/get-all-trainees');
	}

	getTraineesByLicense(licenseType: string, licenseAuthority: string = null, page: number = 1, size: number = 10) {
		let params: HttpParams = new HttpParams();
		if (licenseType) 		params = params.append('licenseType', licenseType);
		if (licenseAuthority) 	params = params.append('licenseAuthority', licenseAuthority);
		params = params.append('page', page.toString());
		params = params.append('size', size.toString());
		return this.httpService.get('user/get-trainees-by-license', params);
	}
	
	createTraineeExpense(body) {
		return this.httpService.post('finance/trainee/create-income', body);
	}

	updateTraineeExpense(id, body) {
		return this.httpService.put('finance/trainee/update-income/' + id, body);
	}

	getTraineeExpense(id){
		return this.httpService.post('finance/trainee/get-income/' + id);
	}

	getTotalIncomeFromTrainee(traineeId: string) {
		let params: HttpParams = new HttpParams();
		params = params.append('id', traineeId);
		return this.httpService.get('finance/trainee/get-total-income-from-trainee', params);
	}

	// get all of a trainee's incomes by trainee id
	getTraineeIncomes(id){				
		return this.httpService.post('finance/trainee/get-income-by-trainee/' + id);
	}

	// get trainee incomes within a specified time range
	getTraineeIncomesWithinTimeRange(startDate: Date, endDate: Date) {
		let params: HttpParams = new HttpParams();
		params = params.append('startDate', startDate.toISOString());
		params = params.append('endDate', endDate.toISOString());
		return this.httpService.get('finance/trainee/get-trainee-incomes-within-time-range', params);
	}

	// get all trainee payments made from specified dates
	getAllTraineePaymentsFromDates(startDate: Date, endDate: Date) {
		let params: HttpParams = new HttpParams();
		params = params.append('startDate', startDate.toISOString());
		params = params.append('endDate', endDate.toISOString());
		
		return this.httpService.get('finance/trainee/get-all-trainee-payments-from-dates', params);
	}

	getAllTraineeExpenses(){
		return this.httpService.post('finance/trainee/get-all-income');
	}

	deleteTraineeExpense(body) {
		return this.httpService.post('', body);
	}
	
	deleteTraineeExpenses(ids) {
		return this.httpService.post('finance/trainee/delete-trainee-incomes', { selectedIds: ids });
	}

	/** Universal Expenses */
	getExpenseComparison(expenseType: 'maintenance' | 'instructor' | 'fuel',
						 currentMonthName?: string, 
						 currentYear?: number, 
						 previousMonthName?: string, 
						 previousYear?: number) {
		let params: HttpParams = new HttpParams();
		params = (expenseType)  	 ? 	params.append('expenseType', expenseType) 				: params;
		params = (currentMonthName)  ? 	params.append('currentMonthName', currentMonthName) 	: params;
		params = (previousMonthName) ? 	params.append('previousMonthName', previousMonthName) 	: params;
		params = (currentYear) 		 ? 	params.append('currentYear', currentYear.toString()) 	: params;
		params = (previousYear) 	 ?  params.append('previousYear', previousYear.toString()) 	: params;
		return this.httpService.get('finance/expense/get-expense-comparison', params);
	}


	/**maintenance Expense API */
	getMaintenanceExpenses() {
		return this.httpService.get('finance/maintenance/get-all-expense');
	}
	
	getMaintenanceExpense(id) {
		return this.httpService.get('finance/maintenance/get-expense/'+id);
	}

	getMaintenanceExpenseFromDates(startDate: Date, endDate: Date) {
		let params: HttpParams = new HttpParams();
		params = params.append('startDate', startDate.toISOString());
		params = params.append('endDate', endDate.toISOString());
		return this.httpService.get('finance/maintenance/get-all-maintenance-expenses-from-dates', params);
	}

	createMaintenanceExpense(body) {
		return this.httpService.post('finance/maintenance/create-expense', body);
	}
	updateMaintenanceExpense(id, body) {
		return this.httpService.put('finance/maintenance/update-expense/'+id,  body);
	}
	deleteMaintenanceExpenses(selectedIds) {
		return this.httpService.post('finance/maintenance/delete-expenses', { selectedIds: selectedIds });
	}
	getMaintenanceExpensesByAircraft(id) {
		return this.httpService.get('finance/maintenance/get-expense-by-aircraft/'+id)
	}

	/** STRIPE API */
	/** Creates a stripe user account and attaches the stripe id to the atims user  */
	createStripeAccountForUser(userId: string,
							   name: string,
							   email: string,
							   cardNumber: string,
							   cardExpirationMonth: number,
							   cardExpirationYear: number,
							   cardCvc: number,
							   city: string = null,
							   state: string = null,
							   country: string = null,
							   addressLineOne: string = null,
							   addressLineTwo: string = null,
							   postalCode: string = null,
							   phoneNumber: string = null) {
		const body: object = { userId, name, email, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvc };
		if (city) body['city'] = city;
		if (state) body['state'] = state;
		if (country) body['country'] = country;
		if (addressLineOne) body['addressLineOne'] = addressLineOne;
		if (addressLineTwo) body['addressLineTwo'] = addressLineTwo;
		if (postalCode) body['postalCode'] = postalCode;
		if (phoneNumber) body['phoneNumber'] = phoneNumber;

		return this.httpService.post('finance/stripe/create-stripe-account-for-user', body);
	}

	/** Creates a draft Stripe invoice  */
	createStripeInvoice(amountInSmallestCurrencyUnit: number,
						customerId: string,
						memo: string,
						dueDate: Date,
						incomeId: string = undefined,
						currency: string = 'usd') {
		const body: object = {
			amountExpected: amountInSmallestCurrencyUnit.toString(),
			customerId: customerId,
			memo: memo,
			dueDate: dueDate.toString(),
			incomeId: incomeId,
			currency: currency
		};
		return this.httpService.post('finance/stripe/invoices', body);
	}

	/** Sends an invoice to a user with a connected stripe account  */
	sendStripeInvoice(invoiceId: string) {
		return this.httpService.post('finance/stripe/invoices/send', { invoiceId });
	}

	/** GET NAME FROM ID*/
	getUserNameById(id)
	{
		return this.httpService.post('/user/get-user-name', {id: id});
	}

	
	/* Schedule apis */
	createSchedule(body) {
		return this.httpService.post('schedule/create-schedule', body);
	}
	updateSchedule(id, body) {
		return this.httpService.put('schedule/' + id, body);
	}
	getSchedules(){
		return this.httpService.get('schedule/get-all-schedules');
	}

	// get all schedules within a time range
	getSchedulesInTimeRange(startDate: Date, endDate: Date, completedOnly: boolean = null) {
		let params: HttpParams = new HttpParams();
		params = params.append('startDate', startDate.toISOString());
		params = params.append('endDate', endDate.toISOString());

		if (completedOnly)
			params = params.append('completedOnly', 'true');
		
		return this.httpService.get('schedule/get-schedules-in-time-range', params);
	}

	getLatestSchedules(amountSchedules: number,
					   licenseType: string = null,
					   completedOnly: boolean = null) {
		let params: HttpParams = new HttpParams();
		params = params.append('amountSchedules', amountSchedules.toString());

		if (licenseType !== null)
			params = params.append('licenseType', licenseType);

		if (completedOnly !== null)
			params = params.append('completedOnly', completedOnly.toString());

		return this.httpService.get('schedule/get-latest-schedules', params);
	}
	
	getIncompleteSchedules(){
		return this.httpService.post('schedule/get-incomplete-schedules');
	}
	getIncompleteFlightSchedules(){
		return this.httpService.post('schedule/get-incomplete-flight-schedules');
	}
	getCompleteSchedules(){
		return this.httpService.post('schedule/get-complete-schedules');
	}
	getRequestedSchedules(){
		return this.httpService.get('schedule-automation/get-all-schedule-requests');
	}
	getAuthorizedSchedules(){
		return this.httpService.get('schedule/get-authorized-schedules');
	}
	getUsersByIds(body){
		return this.httpService.post('user/get-user-names', body);
	}
	deleteSchedules(body){
		return this.httpService.post('schedule/delete-schedules', body);
	}
	deleteSchedulesByResource(body){
		return this.httpService.post('schedule/delete-schedules-by-resources', body);
	}
	changeSchedulesStatus(body){
		return this.httpService.post('schedule/change-status', body);
	}
	getSchedule(id,isScheduleRequest){
		if(isScheduleRequest === undefined){
			return this.httpService.get('schedule/get-schedule/' + id);
		}
		return this.httpService.post('schedule-automation/get-schedule-request-by-id/' + id);
	}
	getAllCourses(){
		return this.httpService.post('course/get-courses');
	}
	postCourseCSV(file){
		return this.httpService.post_csv('course/upload-course-csv', file);
	}
	getSampleCSV(){
		return this.httpService.get_csv('course/download-sample-csv');
	}
	getCourse(id){
		return this.httpService.get('course/get-course/' + id);
	}
	updateCourse(id, body){
		return this.httpService.put('course/' + id, body);
	}
	getCourseByScheduleAndCourseId(body){
		return this.httpService.post('course/get-course-by-schedule-and-course-id', body);
	}

	getTraineeCourseProgress(courseId: string, traineeId: string) {
		let params: HttpParams = new HttpParams();
		params = params.append('courseId', courseId);
		params = params.append('traineeId', traineeId);
		return this.httpService.get('course/get-trainee-course-progress', params);
	}

	deleteCourseScoreByScheduleId(body){
		return this.httpService.post('course/delete-course-score-by-schedule-id', body);
	}
        //Maintenance Schedule apis
	//Get all maintenance schedules
	getAllMaintenanceSchedules(){
		return this.httpService.post('maintenance/maintenance-schedule/get-maintenance-schedules');
	}

	getCompletedMaintenanceSchedules(){
		return this.httpService.get('maintenance/maintenance-schedule/get-completed-maintenance-schedules');
	}

	getDueOrCompletedMaintenanceSchedules(){
		return this.httpService.post('maintenance/maintenance-schedule/get-due-or-completed-maintenance-schedules');
	}
	getInMaintenanceSchedules(){
		return this.httpService.post('maintenance/maintenance-schedule/get-all-in-maintenance-schedules');
	}
	createMaintenanceSchedule(body){
		return this.httpService.post('maintenance/maintenance-schedule/create-maintenance-schedule', body);
	}
	getMaintenanceSchedule(id){
		return this.httpService.get('maintenance/maintenance-schedule/' + id);
	}
	getDueMaintenanceScheduleByResource(body){
		return this.httpService.post('maintenance/maintenance-schedule/get-due-maintenance-schedules-by-resource', body);
	}
	updateMaintenanceSchedule(id, body){
		return this.httpService.put('maintenance/maintenance-schedule/' + id, body);
	}
	deleteMaintenanceSchedule(body){
		return this.httpService.post('maintenance/maintenance-schedule/delete-maintenance-schedules',body);
	}
	deleteMaintenanceScheduleByResources(body){
		return this.httpService.post('maintenance/maintenance-schedule/delete-maintenance-schedules-by-resources', body);
	}
	
	/** 
	 * MAINTENANCE ACTIVITIES API SERVICE
	 */
	getAllMaintenanceActivity(){
		return this.httpService.post('maintenance/maintenance-activity/get-all-maintenance-activities');
	}
	getMaintenanceActivitesByMaintenanceSchedule(body){
		return this.httpService.post('maintenance/maintenance-activity/get-maintenance-activities-by-maintenance-schedule', body);
	}
	createMaintenanceActivity(body){
		return this.httpService.post('maintenance/maintenance-activity/create-maintenance-activity', body);
	}
	maintenanceScheduleConfirmInspectionPasscode(body){
		return this.httpService.post('maintenance/maintenance-schedule/confirm-code-change-status', body);
	}
	getMaintenanceActivity(id){
		return this.httpService.get('maintenance/maintenance-activity/'+ id);
	}
	getAllMaintenanceActivities(){
		return this.httpService.post('maintenance/maintenance-activity/get-all-maintenance-activities');
	}
	editMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/update-maintenance-activity/'+ id, body);
	}
	deleteMaintenanceActivities(body){
		return this.httpService.post('maintenance/maintenance-activity/delete-maintenance-activities', body);
	}
	addMaintenanceMainServiceToMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/add-maintenance-main-service-to-maintenance-activity/'+ id, body);
	}
	addInspectorToMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/add-inspector-to-maintenance-activity/' + id, body);
	}
	addMaintenanceAdditionalServiceToMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/add-maintenance-additional-service-to-maintenance-activity/'+ id, body);
	}
	addMaintenancePartsToMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/add-maintenance-needed-parts-to-maintenance-activity/'+ id, body);
	}
	removeMaintenanceMainServicesFromMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/remove-maintenance-main-services-from-maintenance-activity/' + id, body);
	}
	removeMaintenanceAdditionalServicesFromMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/remove-maintenance-additional-services-from-maintenance-activity/' + id, body);
	}
	removeMaintenancePartsFromMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/remove-maintenance-parts-from-maintenance-activity/' + id, body);
	}
	updateTotalPriceForMaintenanceActivity(id, body){
		return this.httpService.put('maintenance/maintenance-activity/update-totalPrice-for-maintenance-activity/' + id, body);
	} 
		
	//Maintenance main services
	createMaintenanceMainService(body){
		return this.httpService.post('maintenance/maintenance-main-service/create-maintenance-main-service',body);
	}
	getMaintenanceMainServiceByActivity(body){
		return this.httpService.post('maintenance/maintenance-main-service/get-maintenance-main-services-by-activity', body);
	}
	editMaintenanceMainService(id, body){
		return this.httpService.put('maintenance/maintenance-main-service/' + id,body);
	}
	deleteMaintenanceMainServices(body){
		return this.httpService.post('maintenance/maintenance-main-service/delete-maintenance-main-services', body);
	}
	//Maintenance Additional services
	createMaintenanceAdditionalService(body){
		return this.httpService.post('maintenance/maintenance-additional-service/create-maintenance-additional-service', body);
	}
	getMaintenanceAdditionalServiceByActivity(body){
		return this.httpService.post('maintenance/maintenance-additional-service/get-maintenance-additional-services-by-activity', body);
	}
	editMaintenanceAdditionalService(id, body){
		return this.httpService.put('maintenance/maintenance-additional-service/' + id,body);
	}
	deleteMaintenanceAdditionalServices(body){
		return this.httpService.post('maintenance/maintenance-additional-service/delete-maintenance-additional-services', body);
	}
	//Maintenance Parts
	getAllAvailableMaintenanceParts(){
		return this.httpService.post('maintenance/maintenance-parts/get-all-available-maintenance-parts');
	}
	getMaintenancePartsByActivity(body){
		return this.httpService.post('maintenance/maintenance-parts/get-maintenance-parts-by-activity', body);
	}
	createMaintenancePart(body){
		return this.httpService.post('maintenance/maintenance-parts/create-maintenance-part', body);
	}
	getMaintenancePart(id){
		return this.httpService.get("maintenance/maintenance-parts/"+ id);
	}
	updateMaintenancePart(id, body){
		return this.httpService.put("maintenance/maintenance-parts/update-maintenance-part-by-id/"+ id, body);
	}
	removeMaintenanceActivityFromMaintenanceParts(id, body){
		return this.httpService.put("maintenance/maintenance-parts/remove-maintenance-activity-from-maintenance-part/" + id, body);
	}
	deleteMaintenanceParts(body){
		return this.httpService.post("maintenance/maintenance-parts/delete-maintenance-parts", body);
	}
	
	//Maintenance Activity Confirm codes
	maintenanceActivityConfirmInspectionPasscode(body){		
		return this.httpService.post('maintenance/maintenance-activity/confirm-code-change-status', body);
	}
	maintenanceActivityConfirmCodeEditMaintenanceActivityPasscode(body){
		return this.httpService.post('maintenance/maintenance-activity/confirm-code-edit-maintenance-activity', body);
	}
	
	// Airworthiness Directives
	createAirworthinessDirective(body) {
		return this.httpService.post('maintenance/airworthiness-directives/create-air-directive', body);
	}

  	getAllAirworthinessDirective(){
        return this.httpService.post('maintenance/airworthiness-directives/get-all-ads');
    }
  	updateAirworthinessDirective(id, body) {
        return this.httpService.put('maintenance/airworthiness-directives/' + id, body);
    }

	deleteAirworthinessDirective(body) {
        return this.httpService.post('maintenance/airworthiness-directives/delete-ads', body);
    }

    getAirworthinessDirective(id) {
        return this.httpService.get('maintenance/airworthiness-directives/' + id);
    }

	// Users
	getUserById(id){
		return this.httpService.post('user/get-user-by-id/' + id);
	}
	getAllUsersByIds(selectedIds){
		return this.httpService.post('user/get-users-by-ids', {selectedIds: selectedIds});
	}
	getLoggedInUser(){
		return this.httpService.get('user/get-logged-in-user');
	}
	updateUser(userId, body) {
		return this.httpService.put('user/' + userId, body);
	}
	
	/** 
	 * MAINTENANCE ACTIVITIES API SERVICE
	 */
	getallMaintenanceActivity(){
		return this.httpService.post('maintenance/maintenance-activity/get-all-maintenance-activities');
	}

	/** Fuel Vendor Functions */
	getAllFuelVendors(){
		return this.httpService.post('fuel-vendor/get-all-fuel-vendors');
	}
	getFuelVendorById(id){
		return this.httpService.post('fuel-vendor/get-fuel-vendor/' + id);
	}

	// SCHEDULE AUTOMATION / AVAILABILITIES

	// createAvailability {host, begin, end, season}
	createAvailability(body){
		return this.httpService.post('availabilities/create-availability', body);
	}

	// updateAvailability id of availability and {host, begin, end, season}
	updateAvailability(id, body){
		return this.httpService.put('availabilities/edit-availability/' + id, body);
	}

	getAllAvailabilities(){
		return this.httpService.get('availabilities/get-availabilities');
	}

	getAvailabilitiesByUser(id){
		return this.httpService.get('availabilities/get-availabilities-by-user/' + id);
	}

	deleteAvailabilitiesByIds(selectedIds){
		return this.httpService.post('availabilities/delete-availability', {selectedIds: selectedIds});
	}

  	deleteAvailabilitiesByUser(selectedIds){
		return this.httpService.post('availabilities/delete-availabilities-by-user', {selectedIds: selectedIds});
	}

	findAvailableResources(body){
		return this.httpService.post('schedule-automation/find-available-resources', body);
	}

	findAvailableTrainees(body){
		return this.httpService.post('schedule-automation/find-available-trainees', body);
	}

	findAvailableInstructors(body){
		return this.httpService.post('schedule-automation/find-available-instructors', body);
	}

	// confirm user passcode for completing schedules
	confirmUserPasscodeScheduleCompletion(body){
		return this.httpService.post('schedule-confirmation-password/confirm-completion-code', body);
	}
	// Weather API SERVICE
	getWeatherInfo(location){
		return this.httpService.get_Weather('weather/weather-report', location);
	}

	// Site Settings Service
	getSettings(){
		return this.httpService.get('settings/get-settings');
	}
	createSettings(body){
		return this.httpService.post('settings/create-settings', body)
	}
	updateSettings(id, body){
		return this.httpService.put('settings/' + id, body);
	}


	//Finance Settings
	getFinanceSettings(body){
        return this.httpService.post('book-keeping/get-settings', body)
	}

    createFinanceSettings(body){
        return this.httpService.post('book-keeping/create-settings', body)
	}

	updateFinanceSettings(body){
		return this.httpService.post('book-keeping/update-settings' , body);
	}

	deleteFinanceSettings(body){
		return this.httpService.post('book-keeping/delete-settings', body)
	}
 
}
