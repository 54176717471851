import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';
import { LocalStorageEnums } from './localStorageEnums'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  clear() {
    localStorage.clear();
  }

  getDetails() {
    let userInfo = JSON.parse(localStorage.getItem(LocalStorageEnums.userInfo));
    if (userInfo) {
      let decodeData = decode(userInfo.secretToken);
      userInfo['userId'] = decodeData.id;
    }
    return userInfo;
  }

  setDetails(userInfo) {
    return localStorage.setItem(LocalStorageEnums.userInfo, JSON.stringify(userInfo));
  }

  
  
  getSessionId() {
    let userDetails = this.getDetails();
    if (userDetails && userDetails.secretToken) {
      return userDetails.secretToken;
    }
  }

}
