import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private toastr: ToastrService
  ) {
  }

  show(message, type) {
    if (type === 'success') {
      console.log('hello');
      this.toastr.success(message, 'Success');
    } else if (type === 'error') {
      this.toastr.error(message, 'Error');
    } else if (type === 'warning') {
      this.toastr.warning(message, 'Warning');
    } else if (type === 'info') {
      this.toastr.info(message, 'Info');
    }
  }
}
