import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../service/local-storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
  };

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let userDetails = this.localStorageService.getDetails();
    //super-admin
    if(userDetails.role === "super-admin"){
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
